<template>
      <div class="container">
      <!-- Menü -->
        <div class="py-1 mb-2">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <router-link class="p-2 link-secondary" to="/">Home</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="p-2 link-secondary" :to="{name: 'graduation'}">Érettségi megoldások</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="p-2 link-secondary" to="/programozas">Programozás</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="p-2 link-secondary" to="/moodles">Moodle tanároknak</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="p-2 link-secondary" to="/mester">Mesterprogram</router-link>
                  </li>
<!--                   <li class="nav-item">
                    <a class="p-2 link-secondary" href="#">ECDL</a>
                  </li> -->
<!--                   <li class="nav-item">
                    <a class="p-2 link-secondary" href="#">Mindenféle</a>
                  </li> -->
                  <li class="nav-item">
                    <router-link class="p-2 link-secondary" to="/about">Kapcsolat</router-link>
                  </li> 
                  <li class="nav-item" v-if="user.isLogon">
                    <router-link class="p-2 link-secondary" to="/dashboard">Dashboard</router-link>
                  </li>
                </ul>
                <div class="d-flex justify-content-end align-items-center">
<!--                   <a class="link-secondary" href="#" aria-label="Search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mx-3" role="img" viewBox="0 0 24 24"><title>Search</title><circle cx="10.5" cy="10.5" r="7.5"/><path d="M21 21l-5.2-5.2"/></svg>
                  </a> -->

                  <router-link class="p-2 link-secondary" v-if="user.isLogon" to="/" @click="outLogin()">
                    <i class="fas fa-sign-out-alt"></i>
                  </router-link>
                  <router-link class="p-2 link-secondary" v-else to="/login">
                    <i class="fas fa-sign-in-alt"></i>
                  </router-link>
                </div>
              </div>
          </nav>
        </div>
      </div> 
</template>

<script>


export default {
    name: 'Menu',
    props: {
      user: Object,
    },

    emits: ['userLogon'],

    setup(props, {emit}) {
      const outLogin = () => {            
        localStorage.removeItem('token');
        emit('userLogon')
      }

      return {
        outLogin,
      }
    }

}
</script>

<style>
  #navbarSupportedContent a.router-link-exact-active {
    color: #42b983;
  }

</style>
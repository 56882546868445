import { createRouter, createWebHistory } from 'vue-router'
//import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Home'
    },
    
    component: () => import('../views/Home.vue')
  },
  {
    path:'/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/gprs',
    name: 'Gprs',
    component: () => import('../views/Gprs.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/koszonet',
    name: 'Thanks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Thanks.vue')
  },   
  {
    path: '/moodles',
    name: 'Moodlesum',
    component: () => import('../views/Moodlesum.vue')
  },
  {
    path: '/mester',
    name: 'Mestersum',
    component: () => import('../views/Mestersum.vue')
  },
  {
    path: '/programozas',
    name: 'Programsum',
    component: () => import('../views/Programsum.vue')
  },
  {
    path: '/graduation',
    name: 'graduation',
    component: () => import('../views/Graduation.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  }, 
/*   {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue')
  }, */
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
    <div class="">
      <div class="row p-4 p-lg-4 mb-4 text-white rounded bg-dark">
        <div class="col-12 col-lg-6 px-0">
            <h1 class="display-4 fst-italic">Érettségiznél infóból?</h1>
            <p class="lead my-3">Mostantól nincs kifogás! Segítek! Ám a munka oroszlánrésze a tiéd marad. Holló Tamás vagyok, tanár, a honlapomat elsősorban azért hoztam létre, hogy rendszerezzem az érettségi feladatmegoldásaimat.</p>
            <p class="lead mb-0"><router-link to="about" class="text-white fw-bold">Folytatom az olvasást...</router-link></p>
        </div>
        <div class="col-lg-6">
          <img src="assets/headerkep.png" class="img-fluid d-none d-lg-block">
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Header',    
}
</script>

<style>
.display-4 {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
}
</style>
<template>
    <router-view :bejegyzesek="bejegyzesek"/>     
</template>

<script>
import {cHost} from '../config'
import {reactive, onMounted, ref } from 'vue'

export default {
    name: 'Content',

    setup() {
        const bejegyzesek = ref([]);

        const resmsg = reactive({
            error: '',
            message: '',
            isMsg: false,
        });

/*         const pushBejegyzesek = (responseData) => {
            for (let key in responseData) {
                bejegyzesek.push({...responseData[key], id:key})
            }
        } */

        onMounted(async () => {
            await fetch(cHost+"bejegyzes.php",
            {   method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({mod: 'read', what: 'full'})
            }).then( async response => {
                const resdata = await response.json();
                if (!response.ok) {
                    const error = (resdata & resdata.message || response.status);
                    return Promise.reject(error)
                } 
                if (!resdata.error) {
                    resmsg.error = resdata.error;
                    //pushBejegyzesek(resdata.bejegyzesek)
                    bejegyzesek.value = resdata.bejegyzesek;
                    
                } else {
                    const error = (resdata && resdata.message) || response.status;
                    return Promise.reject(error) 
                }
            }).catch(error => {
                console.log("Hiba",error)
            });
        })
        return {
            resmsg, bejegyzesek,
            //pushBejegyzesek,
        }

    }
}
</script>

<style>

</style>
<template>
<div>
  <Menu :user="user" @userLogon="changeUserLogon()"></Menu>
  <main class="container">
    <Header v-if="!user.isLogon"></Header> 
    <Content />
    <Footer></Footer>
  </main>
</div>
</template>

<script>
import Menu from './components/Menu'
import Header from './components/Header'
import Footer from './components/Footer'
import Content from './components/Content'
import { reactive, onMounted } from 'vue'
export default {
  name: 'App',
  components: {
    Menu,
    Header,
    Footer,
    Content,
  },
  setup() {
    const user = reactive({
      isLogon: false,
      uToken: '',
    });

    const changeUserLogon = () => {
      user.isLogon = false
      user.uToken = ''
    }
    

    onMounted(async () => {
      user.uToken = localStorage.getItem('token');
        if (!user.uToken) {
          user.isLogon = false;
        } else {
          user.isLogon = true;
        }       

    })

    return {
        user, 
        changeUserLogon,
      }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

#app {
  font-family: 'Rubik', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman", serif/*rtl:Amiri, Georgia, "Times New Roman", serif*/;
}

</style>

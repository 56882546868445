<template>
    <footer class="blog-footer">
        <p>Az eredeti template <a href="https://getbootstrap.com/">Bootstrap</a> a <a href="https://twitter.com/mdo">@mdo</a> csapattól származik.</p>
        <p>
            <a href="#">Vissza a tetejére</a>
        </p>
    </footer>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.blog-footer {
  padding: 2.5rem 0;
  margin-top: 1rem;
  color: #727272;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
.blog-footer p:last-child {
  margin-bottom: 0;
}
</style>